import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Layout, Wrapper, Header, Content, SectionTitle, CanvasAnimation3 } from '../components';

import config from '../../config/SiteConfig';
import PageProps from '../models/PageProps';

export default class ContactPage extends React.Component<PageProps> {
  public render() {
    return (
      <Layout>
        <Helmet title={`Contact | ${config.siteTitle}`} />
        <CanvasAnimation3 />
        <Header>
          <SectionTitle uppercase={true}>Contact</SectionTitle>
        </Header>
        <Wrapper>
          <a href="https://www.facebook.com/hitimaJP">Facebook</a>
          <br />
          <a href="https://twitter.com/_hitima">Twitter</a>
          <br />
          <a href="https://www.linkedin.com/in/hitoshi-kojima-61b259141/">Linkedin</a>
        </Wrapper>
      </Layout>
    );
  }
}
